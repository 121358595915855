import { shouldLog, log } from '../jdl-helpers.mjs';

const components = [
	{ name: 'amenities', selectors: ['.amenity-b'], shared: true },
	{ name: 'ebrochure', selectors: ['#ebrochure'], shared: true },
	{
		name: 'featured-gallery',
		selectors: [
			'.footer__instagram:not(:has(a[data-fancybox="instagram"]))',
			'.footer__instagram-column.footer__instagram-column--images:not(:has(a[data-fancybox="instagram"]))',
			'.homepage__gallery',
			'#gallerySection',
		],
		shared: true,
	},
	{
		name: 'form',
		selectors: ['form'],
		shared: true,
	},
	{ name: 'gallery', selectors: ['#content .gallery'], shared: true },
	{ name: 'homepage-hero', selectors: ['#homepage_rotator'], shared: true },
	{
		name: 'link',
		selectors: [
			'header',
			'.homepage__about',
			'.homepage__callout',
			'.parallax-section',
			'#page-title',
			'.landing-page__content-wrap',
			'.landing-page__content-block',
			'.ff__section--generic',
			'.contact-b__location',
			'.residents-a',
			'.retail-page__container',
			'.footer__banner',
			'.footer',
		],
		shared: true,
	},
	{ name: 'menu', selectors: ['#menu'], shared: true },
	{
		name: 'neighborhood',
		selectors: ['#neighborhood-map-body'],
	},
	{
		name: 'popdown',
		selectors: ['#popdown-outer-stage'],
		shared: true,
	},
	{
		name: 'retail',
		selectors: ['.retail-page__retailers'],
	},
	{ name: 'review-carousel', selectors: ['#reviews-section'], shared: true },
	{ name: 'reviews', selectors: ['.reviews-page__container'], shared: true },
	{
		name: 'shopping',
		selectors: ['#jd-fp-app'],
		shared: true,
	},
	{ name: 'tour-scheduler', selectors: ['.ts__form-button--submit'] },
	{
		name: 'virtual-tours',
		selectors: ['[data-tab-container="virtual-tours"]'],
		shared: true,
	},
];

components.forEach((component) => {
	const name = component.name;
	const selector = component.selectors.join(', ');

	if (document.querySelectorAll(selector).length > 0) {
		if (!component.shared) {
			import(`./${name}.jdl.js`).then((module) => {
				if (typeof module.handleEvents === 'function') {
					module.handleEvents();
					shouldLog && log(`Loaded ${name} handler.`);
				}
			});
		} else {
			import(`../shared/${name}.jdl.js`).then((module) => {
				if (typeof module.handleEvents === 'function') {
					module.handleEvents();
					shouldLog && log(`Loaded ${name} handler.`);
				}
			});
		}
	}
});
